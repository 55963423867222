import React, { useEffect, useState } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { Map } from "@vis.gl/react-maplibre";
import "maplibre-gl/dist/maplibre-gl.css";
import { FeatureCollection, Feature, Geometry } from "geojson";

const MAP_STYLE = "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json";
const INITIAL_VIEW_STATE = {
    longitude: 94.0,
    latitude: 61.0,
    zoom: 2.5,
    pitch: 0,
    bearing: 0,
};

const getRegionColor = (regionName: string): [number, number, number, number] => {
    const hash = regionName.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return [(hash * 123) % 255, (hash * 321) % 255, (hash * 213) % 255, 150];
};

const MapComponent: React.FC = () => {
    const [geoJsonData, setGeoJsonData] = useState<FeatureCollection | null>(null);

    useEffect(() => {
        fetch("/regions.geojson")
            .then(response => response.json())
            .then((data: FeatureCollection) => {
                setGeoJsonData(data);
            });
    }, []);

    const layers = geoJsonData ? [
        new GeoJsonLayer({
            id: "regions-layer",
            data: geoJsonData,
            filled: true,
            stroked: true,
            getFillColor: (d: Feature<Geometry, { region?: string }>) =>
                getRegionColor(d.properties?.region || "Unknown"),
            getLineColor: [30, 30, 30, 200],
            lineWidthMinPixels: 0.5,
            pickable: true,
            autoHighlight: true,
            highlightColor: [255, 255, 0, 100],
            wrapLongitude: true,
            coordinateSystem: 1,

            onClick: ({ object }) => {
                if (object?.properties?.region) {
                    alert(`Выбрано: ${object.properties.region}`);
                }
            },
            updateTriggers: {
                getFillColor: [geoJsonData]
            }
        })
    ] : [];

    useEffect(() => {
        fetch("/regions.geojson")
            .then(response => response.json())
            .then((data: FeatureCollection) => {
                console.log("Loaded GeoJSON:", data);
                setGeoJsonData(data);
            });
    }, []);


    return (
        <DeckGL
            initialViewState={INITIAL_VIEW_STATE}
            controller={true}
            layers={layers}
            getTooltip={({ object }) => object?.properties?.region && {
                html: `<b>${object.properties.region}</b>`,
                style: { color: "white" }
            }}
        >
            <Map
                mapStyle={MAP_STYLE}
                attributionControl={false}
                // Правильный способ передачи параметров
                glOptions={{
                    preserveDrawingBuffer: true
                }}
            />
        </DeckGL>
    );
};

export default MapComponent;