import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
// import RegionPage from "./pages/RegionPage";
// import ArtPage from "./pages/ArtPage";
// import AdminPage from "./pages/AdminPage";

export default function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                {/*<Route path="/region/:id" element={<RegionPage />} />*/}
                {/*<Route path="/art/:id" element={<ArtPage />} />*/}
                {/*<Route path="/admin" element={<AdminPage />} />*/}
            </Routes>
        </BrowserRouter>
    );
}