import { Link } from "react-router-dom";
import MapComponent from "../components/MapComponent";

export default function HomePage() {
    return (
        <div>
            <h1>Карта регионов</h1>
            <MapComponent />
            <Link to="/admin">Админ-панель</Link>
        </div>
    );
}
